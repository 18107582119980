<template>
  <div>
    <div class="container mt-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.information') }}</li>
        </ol>
      </nav>
      <div class="card border-primary rounded-0">
        <div class="card-header p-0">
          <div class="bg-primary text-white py-2">
            <h3 class="text-white">{{ $t('access.title') }}</h3>
          </div>
        </div>
        <div class="card-body p-3">
          <div class="form-group">
            <label class="font-weight-bold"> {{ $t('access.fullName') }} </label>
            <div class="input-group">
              <input
                type="text"
                v-model="form.fullName"
                class="form-control"
                :placeholder="$t('access.fullName') + '*'"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.adress') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <input
                type="text"
                v-model="form.adress"
                class="form-control"
                :placeholder="$t('access.adress') + '*'"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.email') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <input
                type="email"
                v-model="form.email"
                class="form-control"
                :placeholder="$t('access.email') + '*'"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.phone') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <input
                type="text"
                v-model="form.phone"
                class="form-control"
                :placeholder="$t('access.phone') + '*'"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.fax') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <input
                type="text"
                v-model="form.fax"
                class="form-control"
                :placeholder="$t('access.fax')"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.pp') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <input
                type="text"
                v-model="form.pp"
                class="form-control"
                :placeholder="$t('access.pp')"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('access.requestedInformation') }}</label>
            <div class="input-group mb-2 mb-sm-0">
              <textarea
                v-model="form.requestedInformation"
                class="form-control"
                :placeholder="$t('access.requestedInformation')"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <form>
              <label class="font-weight-bold">{{ $t('access.type') }}</label>
              <label
                ><input
                  type="radio"
                  value="على شكل نسخة ورقية"
                  name="optradio"
                  v-model="form.type"
                />
                <span>{{ $t('access.papier') }}</span>
                </label
              >
              <label
                ><input
                  type="radio"
                  value="على شكل نسخة الكترونية"
                  name="optradio"
                  v-model="form.type"
                  checked
                />
                <span>{{ $t('access.electronique') }}</span>
                </label
              >
              <label>
                <input
                  type="radio"
                  value="مقتطفات من المعلومة"
                  name="optradio"
                  v-model="form.type"
                />
                <span>{{ $t('access.extrait') }}</span>
              </label>
            </form>
          </div>
          <div class="text-center">
            <input
              type="submit"
              @click="envoyer"
              :value="$t('contact.send')"
              class="btn btn-primary btn-block rounded-0 py-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Vuejs slides
import apiAxios from '@/services/axios'
import axios from 'axios'


export default {
  name: 'Access',
  data: () => ({
    form: {
      fullName: null,
      adress: null,
      email: null,
      phone: null,
      fax: null,
      pp: null,
      requestedInformation: null,
      type: 'على شكل نسخة الكترونية',
      
    },
    apiUrl: process.env.VUE_APP_API_URL,
  }),
  methods: {
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description: desc,
      })
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    envoyer() {
      if (this.form.fullName && this.form.email && this.form.requestedInformation) {
        if (this.validateEmail(this.form.email)) {
          axios
            .put(this.apiUrl + '/public/accessInformation', {
              data: this.form,
            })
            .then(res => {
              this.openNotification('success', 'Demande', 'Demande envoyer avec succès')
            })
            .catch(e => {
              this.openNotification('error', 'Demande', "Erreur lors d'envoie")
            })
            .finally(() => {
              this.form.fullName = null
              this.form.adress = null
              this.form.email = null
              this.form.phone = null
              this.form.fax = null
              this.form.pp = null
              this.form.requestedInformation = null
              this.form.type = null
            })
        } else {
          this.openNotification('error', 'Demande', 'Inserer un email correcte!')
        }
      } else {
        this.openNotification('error', 'Demande', 'Remplir tous les champs!')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 10em;
}

/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */
  /*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#fff, $primary, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em $primary;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em; // or something higher...
    transition: 0.25s ease;
    &:hover {
      background-color: mix(#fff, $primary, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $primary;
    }
  }
}

// End Codepen spesific styling
</style>
